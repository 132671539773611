<template>
  <DialogFormWrapper class="file-add">
    <template #form>
      <FileDragDrop
        v-model="files"
        @error="showErrors"
      />
    </template>

    <template #submit>
      <v-btn
        v-if="isUploadCompleted"
        depressed
        color="primary"
        class="base-hover"
        @click="clearForm"
      >
        Dodaj kolejne
      </v-btn>
      <v-btn
        v-else
        color="primary"
        class="base-hover"
        :disabled="!files.length"
        :loading="isProcessing"
        v-shortkey="{enter:['enter']}"
        @shortkey.native="sendFiles"
        @click="sendFiles"
      >
        Załącz plik
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FileDragDrop from './Inputs/FileDragDrop'
import DialogFormWrapper from './Partials/DialogFormWrapper'

export default {
  components: {
    FileDragDrop,
    DialogFormWrapper
  },

  data () {
    return {
      isFormProcessing: false,
      isUploadCompleted: false,
      files: []
    }
  },

  computed: {
    ...mapState({
      isProcessing: state => state.order.isProcessing
    }),
    allUploadsCompleted () {
      return !!this.files.length && this.files.every((file) => file.status === 'uploaded' || file.status === 'error')
    }
  },

  watch: {
    allUploadsCompleted (status) {
      if (status) {
        this.isFormProcessing = false
        this.isUploadCompleted = true
      }
    }
  },

  methods: {
    ...mapActions({
      showSnackbar: 'snackbar/showSnackbar',
      uploadOrderFile: 'order/uploadOrderFile'
    }),

    showErrors (errors) {
      this.showSnackbar({
        message: errors,
        type: 'error'
      })
    },

    sendFiles () {
      this.isFormProcessing = true

      this.files.forEach((file, i) => {
        const formData = new FormData()
        formData.append('file', file.data)

        this.files[i].status = 'processing'

        this.uploadOrderFile(formData)
          .then(() => {
            this.files[i].status = 'uploaded'
          })
          .catch(() => {
            this.files[i].status = 'error'
          })
      })
    },
    clearForm () {
      this.files.splice(0, this.files.length)
      this.isUploadCompleted = false
    }
  }
}
</script>
